<script>
// import api from "@/helpers/api-rest/api.js";
import format from 'date-fns/format';

export default {
  props: {
    id: { type: String, default: "ModalActions" },
    action: {
      type: Object,
      default: () => {
        return {
          id: null,
        };
      },
    },
  },
  data() {
    return {
    };
  },
  computed: {
    modalTitle() {
        return this.action?.name;
    },
  },
  methods: {
    loadData(){
        this.action.init_date = format(new Date(this.action?.init_date), 'dd/MM/yyyy');
        this.action.end_date = format(new Date(this.action?.end_date), 'dd/MM/yyyy');
    },
    toggle(element) {
      element.showDetail = !element.showDetail;
    },
  },
};
</script>
<template>
  
  <b-modal :id="id" hide-footer :title="modalTitle" @shown="loadData" scrollable size="xl">
    <template #default="{ }">
        <form @submit.prevent="submitForm" enctype="multipart/form-data">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-3">
                            <h6>Nombre</h6>
                            <p class="text-muted">
                                {{ action?.name }}
                            </p>
                        </div>
                        <div class="col-lg-3">
                            <h6>Responsable</h6>
                            <p class="text-muted">
                                {{ action?.responsables }}
                            </p>
                        </div>
                        <div class="col-lg-3">
                            <h6>Fecha inicial</h6>
                            <p class="text-muted">
                                {{ action?.init_date }}
                            </p>
                        </div>
                        <div class="col-lg-3">
                            <h6>Fecha final</h6>
                            <p class="text-muted">
                                {{ action?.end_date }}
                            </p>
                        </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-lg-12 mt-4">
                        <h6>Descripción</h6>
                        <p class="text-muted">
                            {{ action?.descriptions }}
                        </p>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-lg-12 mt-4">
                        <h6>Plan de contingencia</h6>
                        <p class="text-muted">
                            {{ action?.contingency_plan }}
                        </p>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-lg-12 mt-4">
                        <h6>Riesgos</h6>
                        <p class="text-muted">
                            {{ action?.risks }}
                        </p>
                      </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Actividades educativas</h4>
                    <table class="table table-centered datatable dt-responsive nowrap mt-4" style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Metodología</th>
                            </tr>
                        </thead>
                        <tbody class="table-group-divider">
                            <template v-for="activity in action.education_activities">
                                <tr :key="activity.id">
                                    <td>{{ activity?.name }}</td>
                                    <td>{{ activity?.methodology }}</td>
                                </tr>
                            </template>
                            <tr v-if="!action.education_activities || action.education_activities.length == 0">
                                <td colspan="4" class="text-center">
                                    No hay registros
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Previsión de coste económicos</h4>
                    <table class="table table-centered datatable dt-responsive nowrap mt-4" style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                        <thead>
                            <tr>
                                <th>Material</th>
                                <th>Coste (€)</th>
                            </tr>
                        </thead>
                        <tbody class="table-group-divider">
                            <template v-for="forecast in action.economic_forecats">
                                <tr :key="forecast.id">
                                    <td>{{ forecast.material }}</td>
                                    <td>{{ forecast.cost }}</td>
                                </tr>
                            </template>
                            <tr v-if="!action.economic_forecats || action.economic_forecats.length == 0">
                                <td colspan="4" class="text-center">
                                    No hay registros
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </form>
    </template>
  </b-modal>
</template>