<script>
import api from "@/helpers/api-rest/api.js";
import format from 'date-fns/format';
import ResumeInvoiceModal from "./resume-invoice-modal.vue";

export default {
  props: {
    id: { type: String, default: "ModalPeriodicTracking" },
    essayId: { type: [Number, String] },
    tracking: {
      type: Object,
      default: () => {
        return {
          id: null,
          tracking_control_economic: []
        };
      },
    },
  },
  components: { ResumeInvoiceModal },
  data() {
    return {
      isModalOpen: false,
      dataUser: [],
      selectOptionsTrackingStatus: [],
      selectOptionsActions: [],
      collapseOpen: [],
      resumeInvoiceModalId: 'ModalResumeInvoice',
      resumeEdit: undefined,
      loading: false,
    };
  },
  computed: {
    modalTitle() {
        return this.tracking?.id ? "Editar seguimiento" : "Añadir seguimiento";
    },
    submitButtonText() {
      return this.tracking?.id ? 'Guardar' : 'Añadir';
    },
  },
  created() {
    this.loadSelectOptionsTrackingStatus();
  },
  methods: {
    loadSelectOptionsTrackingStatus(){
        const apiEndpoint = `/api/v1/tracking-status`;
        api.get({url:apiEndpoint})
        .then(response => {
            this.selectOptionsTrackingStatus = response?.data?.data;
        })
        .catch(error => {
            console.error("Error al cargar los estados:", error);
        });
    },
    loadActionsForEssay(){
      const apiEndpoint = `/api/v1/essay-actions?essay_id=${this.essayId}`;
      api.get({url:apiEndpoint})
      .then(response => {
          this.selectOptionsActions = response?.data?.data;
          this.selectOptionsActions.map(action => {
            action.init_date = format(new Date(action.init_date), 'dd/MM/yyyy');
            action.end_date = format(new Date(action.end_date), 'dd/MM/yyyy');
            return action;
          });
          console.log(this.selectOptionsActions);
      })
      .catch(error => {
          console.error("Error al cargar las acciones:", error);
      });
    },
    addRowControlEconomic(){
      this.tracking.tracking_control_economic.push({
        id: null,
        tracking_id: this.tracking.id,
        material: '',
        cost: '',
        document_path: '',
        document_name: 'Selecciona un archivo...',
      });
    },
    deleteRowControlEconomic(key){
      this.tracking?.tracking_control_economic.splice(key, 1);
    },
    onFileChange(event, type) {
      this.file = event.target.files[0];
      this.uploadFile(this.file, 'trackings')
      .then((response) => {
        const file = response?.data?.data;
        switch (type) {
          case 'graphic':
            this.tracking.document_path_graphic = file.path;
            this.tracking.document_name_graphic = this.file.name;
            break;
          case 'technical':
            this.tracking.document_path_technical = file.path;
            this.tracking.document_name_technical = this.file.name;
            break;
          case 'formative':
            this.tracking.document_path_formative = file.path;
            this.tracking.document_name_formative = this.file.name;
            break;
        }

      })
      .catch((error) => {
        console.error("Error al cargar el archivo:", error);
      });
    },
    onFileChangeControl(event, control){
      this.file = event.target.files[0];
      this.uploadFile(this.file, 'invoices')
      .then((response) => {
        const file = response?.data?.data;
        control.document_name = this.file.name;
        control.document_path = file.path;
      })
      .catch((error) => {
        console.error("Error al cargar el archivo:", error);
      });
    },
    uploadFile(file, folder){
      const formData = new FormData();
      formData.set("file", file);
      formData.append("path", folder);
      const config = { headers: { "content-type": "multipart/form-data" } };

      const apiEndpoint = `/api/v1/upload-file`;
      return api.post({ url: apiEndpoint, data: formData, config })
    },
    toggleCollapse(index) {
      this.$set(this.collapseOpen, index, !this.collapseOpen[index]);
    },
    submitForm() {
        // if(this.center?.id){
        //     return this.updateCenter();
        // }
        // return this.createCenter();
    },
    cancelForm() {
        this.$bvModal.hide(this.id);
    },
    editTracking(resume) {
      this.resumeEdit = resume;
      this.$bvModal.show(this.resumeInvoiceModalId);
    },
    addResumeInvoice(){
      console.log("Crear control económico");
      this.$bvModal.show(this.resumeInvoiceModalId);
    },
    loadData(){
      console.log(this.essayId);
      this.loadActionsForEssay();
    },
  },
};
</script>
<template>
  <div>
    <ResumeInvoiceModal :id="resumeInvoiceModalId" :resume="resumeEdit"/>
    <b-modal :id="id" :title="modalTitle" @shown="loadData" scrollable size="xl">
      <template #default="{ }">
        <form @submit.prevent="submitForm" enctype="multipart/form-data">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Acciones</h4>
              <b-card no-body class="mb-1 shadow-lg mt-4" v-for="(action, i) in selectOptionsActions" :key="i">
                <b-card-header header-tag="header" class="p-3 bg-white" role="tab">
                  <h6 class="m-0 d-flex justify-content-between align-items-center">
                    <div class="form-inline">
                      <span>{{ action.name }} <small class="font-italic ml-3">({{ action.init_date }} - {{ action.end_date }})</small></span>
                    </div>
                    <div>
                      <!-- <b-button size="sm" pill variant="info" class="mr-4">Justificante</b-button> -->
                      <b-icon style="margin-right: 10px" :icon="collapseOpen[i] ? 'chevron-down' : 'chevron-up'" @click="toggleCollapse('collapse-' + i)"  v-b-toggle="'collapse-' + i"></b-icon>
                    </div>
                  </h6>
                </b-card-header>
                <b-collapse :id="'collapse-' + i" accordion="variation-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      <div class="row">
                        <div class="col-lg-3">
                            <h6>Nombre</h6>
                            <p class="text-muted">
                                {{ action?.name }}
                            </p>
                        </div>
                        <div class="col-lg-3">
                          <h6>Responsable</h6>
                          <p class="text-muted">
                              {{ action?.responsables }}
                          </p>
                        </div>
                        <div class="col-lg-3">
                          <h6>Fecha inicial</h6>
                          <p class="text-muted">
                              {{ action?.init_date }}
                          </p>
                        </div>
                        <div class="col-lg-3">
                          <h6>Fecha final</h6>
                          <p class="text-muted">
                              {{ action?.end_date }}
                          </p>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-lg-12 mt-4">
                          <h6>Descripción</h6>
                          <p class="text-muted">
                              {{ action?.descriptions }}
                          </p>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-lg-12 mt-4">
                          <h6>Plan de contingencia</h6>
                          <p class="text-muted">
                              {{ action?.contingency_plan }}
                          </p>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-lg-12 mt-4">
                          <h6>Riesgos</h6>
                          <p class="text-muted">
                              {{ action?.risks }}
                          </p>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col-lg-12">
                          <div class="form-group">
                            <label for="observationTarget">Observaciones</label>
                            <textarea v-model="tracking.observation" class="form-control" id="observationTarget" rows="3" placeholder="Observaciones"></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col-lg-4">
                          <div class="form-group">
                            <label for="file">Documento gráfico</label>
                            <div class="custom-file">
                              <input id="inputFileGrafico" type="file" name="file" class="custom-file-input" @change="event => onFileChange(event, 'graphic')" />
                              <label class="custom-file-label" for="inputFileGrafico">{{ tracking?.document_name_graphic || 'Seleccione un archivo' }}</label>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4">
                          <div class="form-group">
                            <label for="file">Documento técnico</label>
                            <div class="custom-file">
                              <input id="inputFileTecnico" type="file" name="file" class="custom-file-input" @change="event => onFileChange(event, 'technical')" />
                              <label class="custom-file-label" for="inputFileTecnico">{{ tracking?.document_name_technical || 'Seleccione un archivo' }}</label>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4">
                          <div class="form-group">
                            <label for="file">Documento formativo</label>
                            <div class="custom-file">
                              <input id="inputFileFormativo" type="file" name="file" class="custom-file-input" @change="event => onFileChange(event, 'formative')" />
                              <label class="custom-file-label" for="inputFileFormativo">{{ tracking?.document_name_formative || 'Seleccione un archivo' }}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col-lg-12">
                          <label for="file">Control económico</label>
                          <table class="table table-bordered table-sticky-header">
                            <thead>
                              <tr>
                                <th scope="col">Material</th>
                                <th scope="col">Coste (€)</th>
                                <th scope="col">Factura</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(control, i) in tracking?.tracking_control_economic" :key="i" class="">
                                <td>
                                  <div class="form-group">
                                    <input type="text" v-model="control.material" class="form-control" placeholder="Material" />                                          
                                  </div>
                                </td>
                                <td>
                                  <div class="form-group">
                                    <input type="text" v-model="control.cost" class="form-control" placeholder="Coste" />                                     
                                  </div>
                                </td>
                                <td>
                                  <div class="form-group">
                                    <div class="custom-file">
                                      <input id="inputFileInvoice" type="file" name="file" class="custom-file-input" @change="event => onFileChangeControl(event, control)" />
                                      <label class="custom-file-label" for="inputFileInvoice">{{ control?.document_name || 'Seleccione un archivo' }}</label>
                                    </div>                                      
                                  </div>
                                </td>
                                <td class="text-center">
                                    <a
                                        href="javascript:void(0);"
                                        class="text-danger"
                                        v-b-tooltip.hover
                                        title="Eliminar"
                                        @click="deleteRowControlEconomic(i)"
                                    >
                                        <i class="mdi mdi-trash-can font-size-18"></i>
                                    </a>
                                </td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <td colspan="4" class="text-right">
                                    <a href="javascript:void(0);" class="text-success" @click="addRowControlEconomic()">
                                      <i class="mdi mdi-plus-circle font-size-18"></i> Agregar control económico
                                    </a>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                      <div class="row mt-4">
                        <div class="col-lg-12 text-center p-4">
                          <a href="javascript:void(0);" class="text-success" @click="addResumeInvoice()">
                            <i class="mdi mdi-plus-circle font-size-18"></i> Añadir justificación económica
                          </a>
                        </div>
                      </div>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </form>
      </template>
      <template #modal-footer="{  }">
        <b-button variant="success" @click="submitForm()" :disabled="loading"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>{{ submitButtonText }}</b-button>
        <b-button @click="cancelForm()">Cancel</b-button>
      </template>
    </b-modal>
  </div>
</template>