<script>
// import api from "@/helpers/api-rest/api.js";
// import format from 'date-fns/format';

export default {
  props: {
    id: { type: String, default: "ModalResumeInvoice" },
    resume: {
      type: Object,
      default: () => {
        return {
          id: null,
        };
      },
    },
  },
  data() {
    return {
      isModalOpen: false,
      dataUser: [],
      selectOptionsTrackingStatus: [],
      selectOptionsActions: [],
      collapseOpen: [],
    };
  },
  computed: {
    modalTitle() {
        return this.resume?.id ? "Editar justificante" : "Añadir justificante";
    },
  },
  methods: {
    submitForm() {
        // if(this.center?.id){
        //     return this.updateCenter();
        // }
        // return this.createCenter();
    },
    loadData(){
    },
  },
};
</script>
<template>
  
  <b-modal :id="id" :title="modalTitle" @shown="loadData" scrollable size="xl">
    <template #default="{ }">
      <form @submit.prevent="submitForm" enctype="multipart/form-data">
        <!-- <div class="card">
          <div class="card-body">
            <h4 class="card-title">Acciones</h4>
            <b-card no-body class="mb-1 shadow-lg mt-4" v-for="(action, i) in selectOptionsActions" :key="i">
              <b-card-header header-tag="header" class="p-3 bg-white" role="tab">
                <h6 class="m-0 d-flex justify-content-between align-items-center">
                  <div class="form-inline">
                    <span>{{ action.name }}</span>
                  </div>
                  <div>
                    <b-button size="sm" pill variant="info" class="mr-4">Justificante</b-button>
                    <b-icon style="margin-right: 10px" :icon="collapseOpen[i] ? 'chevron-down' : 'chevron-up'" @click="toggleCollapse('collapse-' + i)"  v-b-toggle="'collapse-' + i"></b-icon>
                  </div>
                </h6>
              </b-card-header>
              <b-collapse :id="'collapse-' + i" accordion="variation-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>
                    <div class="row">
                      <div class="col-lg-3">
                          <h6>Nombre</h6>
                          <p class="text-muted">
                              {{ action?.name }}
                          </p>
                      </div>
                      <div class="col-lg-3">
                        <h6>Responsable</h6>
                        <p class="text-muted">
                            {{ action?.responsables }}
                        </p>
                      </div>
                      <div class="col-lg-3">
                        <h6>Fecha inicial</h6>
                        <p class="text-muted">
                            {{ action?.init_date }}
                        </p>
                      </div>
                      <div class="col-lg-3">
                        <h6>Fecha final</h6>
                        <p class="text-muted">
                            {{ action?.end_date }}
                        </p>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-lg-12 mt-4">
                        <h6>Descripción</h6>
                        <p class="text-muted">
                            {{ action?.descriptions }}
                        </p>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-lg-12 mt-4">
                        <h6>Plan de contingencia</h6>
                        <p class="text-muted">
                            {{ action?.contingency_plan }}
                        </p>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-lg-12 mt-4">
                        <h6>Riesgos</h6>
                        <p class="text-muted">
                            {{ action?.risks }}
                        </p>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label for="observationTarget">Observaciones</label>
                          <textarea v-model="tracking.observation" class="form-control" id="observationTarget" rows="3" placeholder="Observaciones"></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label for="file">Documento gráfico <span style="color: red;">*</span></label>
                          <div class="custom-file">
                            <input id="inputFileGrafico" type="file" name="file" class="custom-file-input" @change="event => onFileChange(event, 'graphic')" />
                            <label class="custom-file-label" for="inputFileGrafico">{{ tracking?.document_name_graphic || 'Seleccione un archivo' }}</label>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label for="file">Documento técnico <span style="color: red;">*</span></label>
                          <div class="custom-file">
                            <input id="inputFileTecnico" type="file" name="file" class="custom-file-input" @change="event => onFileChange(event, 'technical')" />
                            <label class="custom-file-label" for="inputFileTecnico">{{ tracking?.document_name_technical || 'Seleccione un archivo' }}</label>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label for="file">Documento formativo <span style="color: red;">*</span></label>
                          <div class="custom-file">
                            <input id="inputFileFormativo" type="file" name="file" class="custom-file-input" @change="event => onFileChange(event, 'formative')" />
                            <label class="custom-file-label" for="inputFileFormativo">{{ tracking?.document_name_formative || 'Seleccione un archivo' }}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-lg-12">
                        <label for="file">Control económico</label>
                        <table class="table table-bordered table-sticky-header">
                          <thead>
                            <tr>
                              <th scope="col">Material</th>
                              <th scope="col">Coste (€)</th>
                              <th scope="col">Factura</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(control, i) in tracking?.tracking_control_economic" :key="i" class="">
                              <td>
                                <div class="form-group">
                                  <input type="text" v-model="control.material" class="form-control" placeholder="Material" />                                          
                                </div>
                              </td>
                              <td>
                                <div class="form-group">
                                  <input type="text" v-model="control.cost" class="form-control" placeholder="Coste" />                                     
                                </div>
                              </td>
                              <td>
                                <div class="form-group">
                                  <div class="custom-file">
                                    <input id="inputFileInvoice" type="file" name="file" class="custom-file-input" @change="event => onFileChangeControl(event, control)" />
                                    <label class="custom-file-label" for="inputFileInvoice">{{ control?.document_name || 'Seleccione un archivo' }}</label>
                                  </div>                                      
                                </div>
                              </td>
                              <td class="text-center">
                                  <a
                                      href="javascript:void(0);"
                                      class="text-danger"
                                      v-b-tooltip.hover
                                      title="Eliminar"
                                      @click="deleteRowControlEconomic(i)"
                                  >
                                      <i class="mdi mdi-trash-can font-size-18"></i>
                                  </a>
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colspan="4" class="text-right">
                                  <a href="javascript:void(0);" class="text-success" @click="addRowControlEconomic()">
                                    <i class="mdi mdi-plus-circle font-size-18"></i> Agregar control económico
                                  </a>
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div> -->
      </form>
    </template>
    <template #modal-footer="{  }">
      <div class="d-flex justify-content-center w-100">
        <!-- <b-button @click="$emit('open-action-modal', 'reject', dataEssay?.id)" variant="danger" class="mx-5 w-25">Rechazar</b-button>
        <b-button @click="$emit('open-action-modal', 'return', dataEssay?.id)" variant="primary" class="mx-5 w-25">Devolución</b-button>
        <b-button @click="$emit('open-action-modal', 'approve', dataEssay?.id)" variant="success" class="mx-5 w-25">Aprobar</b-button> -->
      </div>
    </template>
  </b-modal>
</template>