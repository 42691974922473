<script>
// import api from "@/helpers/api-rest/api.js";
// import format from 'date-fns/format';

export default {
  props: {
    id: { type: String, default: "ModalTargets" },
    target: {
      type: Object,
      default: () => {
        return {
          id: null,
        };
      },
    },
  },
  data() {
    return {
      isModalOpen: false,
      showResults: false,
    };
  },
  computed: {
    modalTitle() {
        // return this.target?.id ? "Editar seguimiento" : "Añadir seguimiento";
        return this.target?.title;
    },
  },
  methods: {
    loadData(){
      console.log(this.target);
    },
    toggle(element) {
      element.showDetail = !element.showDetail;
    },
  },
};
</script>
<template>
  
  <b-modal :id="id" hide-footer :title="modalTitle" @shown="loadData" scrollable size="xl">
    <template #default="{ }">
        <form @submit.prevent="submitForm" enctype="multipart/form-data">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-4">
                            <h6>Título</h6>
                            <p class="text-muted">
                                {{ target?.title }}
                            </p>
                        </div>
                        <div class="col-lg-8">
                            <h6>Descripción</h6>
                            <p class="text-muted">
                                {{ target?.description }}
                            </p>
                        </div>
                        <div class="col-lg-12 mt-3">
                            <div>
                                <h6>Indicador de realización de objetivos</h6>
                                <table class="table table-centered datatable dt-responsive nowrap" style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Descripción</th>
                                            <th>Valores de medida</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="t_achievement in target.target_achievements">
                                            <tr :key="t_achievement.id">
                                            <td>{{ t_achievement.name }}</td>
                                            <td>{{ t_achievement.description }}</td>
                                            <td>{{ t_achievement.measured_values }}</td>
                                            </tr>
                                        </template>
                                        <tr v-if="!target.target_achievements || target.target_achievements.length == 0">
                                            <td colspan="4" class="text-center">
                                            No hay registros
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title mb-4">Resultados</h4>
                    <div class="row">
                        <div class="col-lg-12">
                            <b-card no-body class="mb-1 shadow-lg mt-4" v-for="(result, i) in target.target_results" :key="i">
                                <b-card-header header-tag="header" class="p-3 bg-white" role="tab">
                                    <h6 class="m-0 d-flex justify-content-between align-items-center">
                                        <div class="form-inline">
                                            <span>{{ result.title }}</span>
                                        </div>
                                        <div>
                                            <i v-b-toggle.collapse-results @click="showResults = !showResults" class="mr-4" :class="{'fas fa-chevron-down': !showResults, 'fas fa-chevron-up': showResults}"></i>
                                        </div>
                                    </h6>
                                </b-card-header>
                                <b-collapse id="collapse-results" accordion="variation-accordion" role="tabpanel">
                                    <b-card-body>
                                        <b-card-text>
                                            <div class="row">
                                                <div class="col-lg-4">
                                                    <h6>Título</h6>
                                                    <p class="text-muted">
                                                        {{ result?.title }}
                                                    </p>
                                                </div>
                                                <div class="col-lg-8">
                                                    <h6>Descripción</h6>
                                                    <p class="text-muted">
                                                        {{ result?.description }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="row mt-4">
                                                <div class="col-lg-12">
                                                    <h6>Indicador de realización de resultados</h6>
                                                    <table class="table table-centered datatable dt-responsive nowrap" style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                                                        <thead>
                                                            <tr>
                                                                <th>Nombre</th>
                                                                <th>Descripción</th>
                                                                <th>Valores de medida</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <template v-for="r_achievement in result.result_achievements">
                                                                <tr :key="r_achievement.id">
                                                                <td>{{ r_achievement.name }}</td>
                                                                <td>{{ r_achievement.description }}</td>
                                                                <td>{{ r_achievement.measured_values }}</td>
                                                                </tr>
                                                            </template>
                                                            <tr v-if="!result.result_achievements || result.result_achievements.length == 0">
                                                                <td colspan="4" class="text-center">
                                                                    No hay registros
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </b-card-text>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                            <!-- <table class="table table-centered datatable dt-responsive nowrap" style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                                <thead>
                                    <tr>
                                        <th>Título</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="result in target.target_results">
                                        <tr :key="result.id">
                                            <td>{{ result.title }}</td>
                                        </tr>
                                    </template>
                                    <tr v-show="result.showDetail" :key="'result-details-' + result.id">
                                        <td colspan="3">
                                        <b-card-sub-title class="card-sub-title">Indicador de realización de resultados</b-card-sub-title>
                                        <table class="table table-centered datatable dt-responsive nowrap" style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                                            <thead class="thead-light">
                                            <tr>
                                                <th>Nombre</th>
                                                <th>Descripción</th>
                                                <th>Valores de medida</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <template v-for="r_achievement in result.result_achievements">
                                                <tr :key="r_achievement.id">
                                                <td>{{ r_achievement.name }}</td>
                                                <td>{{ r_achievement.description }}</td>
                                                <td>{{ r_achievement.measured_values }}</td>
                                                </tr>
                                            </template>
                                            <tr v-if="!result.result_achievements || result.result_achievements.length == 0">
                                                <td colspan="4" class="text-center">
                                                No hay datos
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table> -->
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </template>
  </b-modal>
</template>